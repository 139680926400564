<template>
  <div class="mood-tracker-legend">
    <div v-for="mood in moods" :key="mood" class="mood-tracker-legend__item">
      <div
        class="mood-tracker-legend__item-circle"
        :class="`mood-tracker-legend__item-circle--${mood}`"
      ></div>
      <h6>{{ toMoodName(mood) }}</h6>
    </div>
  </div>
</template>

<script>
const moods = ["happy", "content", "soso", "worried", "stressed", "no-entry"];

const toMoodName = mood =>
  ({
    happy: "Happy",
    content: "Content",
    soso: "So-so",
    worried: "Worried",
    stressed: "Stressed",
    "no-entry": "No entry"
  }[mood]);

export default {
  name: "MoodTrackerLegend",
  setup() {
    return { moods, toMoodName };
  }
};
</script>

<style lang="scss" scoped>
.mood-tracker-legend {
  border-top: 1px solid $base-color;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  padding: 24px 32px;
  position: absolute;
  width: 100%;

  &__item {
    align-items: center;
    display: flex;
    flex-direction: row;

    h6 {
      margin: 0 24px 0 8px;
    }

    &-circle {
      box-sizing: border-box;
      background: $base-color;
      border: 2px solid $base-color;
      background-color: transparent;
      border-radius: 8px;
      height: 12px;
      width: 12px;

      &--happy {
        border-color: $color-mood-happy;
        background-color: $color-mood-happy;
      }

      &--content {
        border-color: $color-mood-content;
        background-color: $color-mood-content;
      }

      &--soso {
        border-color: $color-mood-soso;
        background-color: $color-mood-soso;
      }

      &--worried {
        border-color: $color-mood-worried;
        background-color: $color-mood-worried;
      }

      &--stressed {
        border-color: $color-mood-stressed;
        background-color: $color-mood-stressed;
      }
    }
  }
}
</style>
