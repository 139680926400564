<template>
  <div class="container">
    <div class="wrapper">
      <div class="titlesBlock">
        <div class="flex-between">
          <h2>My Mood Insider</h2>
          <select v-model="numberOfWeeks">
            <option :value="4" selected>Last 4 weeks</option>
            <option :value="12">Last 12 weeks</option>
          </select>
        </div>
      </div>
      <div class="mood-insider">
        <div class="mood-insider__col-twothird">
          <MoodTracker :number-of-weeks="numberOfWeeks" />
        </div>
        <div class="mood-insider__col-onethird">
          <MoodTrackerStatistics
            noLink
            :start-date="startDate"
            :end-date="endDate"
          />
          <MoodTrackerToday />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import dayjs from "dayjs";

import MoodTracker from "@/components/mood/MoodTracker.vue";
import MoodTrackerToday from "@/components/mood/MoodTrackerToday.vue";
import MoodTrackerStatistics from "@/components/mood/MoodTrackerStatistics.vue";

export default {
  components: {
    MoodTracker,
    MoodTrackerToday,
    MoodTrackerStatistics
  },
  setup() {
    const numberOfWeeks = ref(4);
    const today = ref(dayjs().startOf("day"));
    const thisWeek = computed(() => today.value.startOf("week"));
    const startDate = computed(() =>
      thisWeek.value.subtract(numberOfWeeks.value - 1, "weeks").startOf("week")
    );
    const endDate = computed(() => thisWeek.value.endOf("week"));

    return {
      numberOfWeeks,
      startDate,
      endDate
    };
  }
};
</script>

<style lang="scss" scoped>
.mood-insider {
  box-sizing: border-box;
  display: flex;
  margin-top: 48px;
  width: 100%;

  h4 {
    padding: 0 32px;
  }

  &__col-twothird {
    margin-right: 20px;
    width: 661px;
  }
  &__col-onethird {
    width: 366px;
  }
}
.flex-between {
  align-items: center;
  display: flex;
}
select {
  background-color: #e7ddd7;
  box-shadow: none;
  font-family: GreycliffCF-Bold;
  font-weight: 500;
  height: 32px;
  line-height: 24px;
  margin: 12px 0 0 116px;
  padding: 4px 28px 4px 8px;
}
</style>
