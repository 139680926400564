<template>
  <div class="mood-tracker" :class="{ 'mood-tracker--compact': compact }">
    <div class="mood-tracker__header">
      <h4>Your mood, overtime</h4>
    </div>

    <div class="mood-tracker__list">
      <div class="mood-tracker__row mood-tracker__row--header">
        <div class="mood-tracker__row-item"></div>
        <div class="mood-tracker__row-item">MON</div>
        <div class="mood-tracker__row-item">TUE</div>
        <div class="mood-tracker__row-item">WED</div>
        <div class="mood-tracker__row-item">THU</div>
        <div class="mood-tracker__row-item">FRI</div>
        <div class="mood-tracker__row-item">SAT</div>
        <div class="mood-tracker__row-item">SUN</div>
      </div>
      <div class="mood-tracker__row" v-for="week in previousWeeks" :key="week">
        <div class="mood-tracker__row-item">Week {{ week.number }}</div>
        <div
          class="mood-tracker__row-item"
          v-for="day in week.days"
          :key="day.date.format()"
          :class="{ 'mood-tracker__row-item--today': isToday(day.date) }"
        >
          <MoodItem
            :value="day.mood"
            :compact="compact"
            :disabled="isAfterToday(day.date)"
            no-hover
          />
        </div>
      </div>
    </div>

    <MoodTrackerLegend />
  </div>
</template>

<script>
import { ref, computed } from "vue";
import dayjs from "dayjs";

import useMoodForRange from "@/composables/useMoodForRange";

import MoodItem from "@/components/mood/MoodItem";
import MoodTrackerLegend from "@/components/mood/MoodTrackerLegend.vue";

const getWeekDays = date =>
  Array.from(Array(7).keys()).map(weekDay =>
    date.weekday(weekDay).startOf("day")
  );

export default {
  name: "MoodTracker",
  components: {
    MoodItem,
    MoodTrackerLegend
  },
  props: {
    numberOfWeeks: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const compact = computed(() => props.numberOfWeeks > 8);

    const today = ref(dayjs().startOf("day"));
    const thisWeek = computed(() => today.value.startOf("week"));
    const startDate = computed(() =>
      thisWeek.value.subtract(props.numberOfWeeks, "weeks")
    );
    const { moods } = useMoodForRange(startDate, today);
    const previousWeeks = computed(() =>
      Array.from(Array(props.numberOfWeeks).keys())
        .map(n => {
          const previousWeek = thisWeek.value.subtract(n, "weeks");
          const weekDays = getWeekDays(previousWeek).map(date => ({
            date,
            mood: getMoodForDay(date)
          }));
          return {
            number: previousWeek.week(),
            days: weekDays
          };
        })
        .reverse()
    );

    const getMoodForDay = day => moods.value[day.format("YYYY-MM-DD")];
    const isToday = day => day.isSame(today.value, "day");
    const isAfterToday = day => day.isAfter(today.value, "day");

    return {
      compact,
      thisWeek,
      previousWeeks,
      isToday,
      isAfterToday
    };
  }
};
</script>

<style lang="scss" scoped>
.mood-tracker {
  background: white;
  box-shadow: $shadow-sm;
  box-sizing: border-box;
  border-radius: $b9;
  height: 606px;
  position: relative;
  width: 100%;

  &--compact {
    .mood-tracker__row {
      padding: 4px 32px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 32px 0;

    select {
      padding: 8px 0;
      height: auto;
      line-height: 24px;
    }
  }

  &__list {
    padding-top: 12px;
  }

  &__row {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 32px;
    width: 100%;
    font-size: 14px;

    &--header {
      font-size: 11px;
      padding: 12px 32px;
    }

    &:nth-child(even) {
      background-color: rgb(255, 249, 245);
    }

    &-item {
      position: relative;
      align-items: center;
      display: flex;
      height: 28px;
      font-weight: 500;
      justify-content: center;
      width: calc(100% / 7);

      &--today {
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          bottom: -8px;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 5px 5px 5px;
          border-color: transparent transparent $base-color transparent;
        }
      }
    }
  }
}
</style>
